import React, { useState, useEffect } from 'react';
import { Button, Form, Accordion, Card } from 'react-bootstrap';
import { ChevronDown, ChevronUp, Check, Settings } from 'lucide-react';

const Switch = ({ checked, onChange, disabled }) => {
  return (
    <Form.Check
      type="switch"
      id={`custom-switch-${Math.random()}`}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(true);
  const [showCustomize, setShowCustomize] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    functional: false,
    marketing: false
  });

  useEffect(() => {
    const storedConsent = localStorage.getItem('cookieConsent');
    if (storedConsent) {
      setShowConsent(false);
      setPreferences(JSON.parse(storedConsent));
    }
  }, []);

  const handleAcceptAll = () => {
    const allAccepted = {
      necessary: true,
      analytics: true,
      functional: true,
      marketing: true
    };
    setPreferences(allAccepted);
    localStorage.setItem('cookieConsent', JSON.stringify(allAccepted));
    setShowConsent(false);
  };

  const handleRejectAll = () => {
    const allRejected = {
      necessary: true,
      analytics: false,
      functional: false,
      marketing: false
    };
    setPreferences(allRejected);
    localStorage.setItem('cookieConsent', JSON.stringify(allRejected));
    setShowConsent(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(preferences));
    setShowConsent(false);
    setShowCustomize(false);
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const togglePreference = (key) => {
    if (key === 'necessary') return;
    setPreferences(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const CookieSection = ({ section }) => {
    const { key, title, description, services } = section;
    const isExpanded = expandedSections[key];
    
    return (
      <Card className="mb-2 border-0 shadow-sm">
        <Card.Header 
          onClick={() => toggleSection(key)} 
          style={{ 
            cursor: 'pointer', 
            background: 'white', 
            border: 'none',
            borderBottom: isExpanded ? '1px solid #e0e0e0' : 'none'
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {isExpanded ? <ChevronUp size={18} color="#c41e3a" /> : <ChevronDown size={18} color="#c41e3a" />}
              <span className="ms-2 fw-bold" style={{fontSize: '16px', color: '#333'}}>{title}</span>
              {key === 'necessary' && (
                <span className="ms-2 badge bg-secondary" style={{fontSize: '10px'}}>Required</span>
              )}
            </div>
            <Switch
              checked={preferences[key]}
              onChange={() => togglePreference(key)}
              disabled={key === 'necessary'}
            />
          </div>
        </Card.Header>
        <Accordion.Collapse in={isExpanded}>
          <Card.Body style={{background: '#f8f9fa', fontSize: '14px'}}>
            <p className="text-muted mb-3">{description}</p>
            {services && services.length > 0 && (
              <div>
                <h6 className="fw-bold mb-2" style={{fontSize: '14px', color: '#555'}}>Services:</h6>
                {services.map(service => (
                  <div key={service.name} className="d-flex justify-content-between align-items-center bg-white p-2 mb-2 rounded">
                    <span style={{fontSize: '12px', color: '#333'}}>{service.name}</span>
                    <small className="text-muted" style={{fontSize: '10px'}}>{service.provider}</small>
                  </div>
                ))}
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  const cookieCategories = [
    {
      key: 'necessary',
      title: 'Necessary',
      description: 'Essential for the proper functioning of our website.',
      services: [
        { name: 'Session Cookie', provider: 'Internal' },
        { name: 'CSRF Token', provider: 'Internal' }
      ]
    },
    {
      key: 'analytics',
      title: 'Analytics',
      description: 'Help us understand how visitors interact with our website.',
      services: [
        { name: 'Google Analytics', provider: 'Google' }
      ]
    },
    {
      key: 'functional',
      title: 'Functional',
      description: 'Enable more personalized features.',
      // services property is not present in this category
    },
    {
      key: 'marketing',
      title: 'Marketing',
      description: 'Used to display relevant advertisements.',
      services: [
        { name: 'Google Ads', provider: 'Google' },
      ]
    }
  ];

  const CookieSettingsButton = () => (
    <Button
      variant="danger"
      onClick={() => setShowConsent(true)}
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        zIndex: 1000,
        borderRadius: '25px',
        padding: '12px 20px',
        fontSize: '16px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        backgroundColor: '#c41e3a',
        color: '#ffffff',
        border: 'none',
        transition: 'all 0.3s ease'
      }}
      onMouseEnter={(e) => {
        e.target.style.transform = 'scale(1.05)';
        e.target.style.boxShadow = '0 6px 12px rgba(0,0,0,0.3)';
      }}
      onMouseLeave={(e) => {
        e.target.style.transform = 'scale(1)';
        e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.2)';
      }}
    >
      <Settings size={20} />
      Cookie Settings
    </Button>
  );

  return (
    <>
      {!showConsent && <CookieSettingsButton />}
      {showConsent && (
        <div className="cookie-consent" style={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          maxWidth: '380px',
          width: 'calc(100% - 40px)',
          maxHeight: '80vh',
          backgroundColor: '#ffffff',
          padding: '25px',
          borderRadius: '15px',
          boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
          zIndex: 1000,
          fontFamily: '"Varela Round", sans-serif',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {!showCustomize ? (
            <>
              <h3 style={{color: '#c41e3a', marginBottom: '20px', fontSize: '22px', fontWeight: 'bold'}}>Our Cookie Preferences</h3>
              <p style={{color: '#333', fontSize: '16px', marginBottom: '25px', lineHeight: '1.6'}}>
                We use cookies to optimize our website and provide you with the best experience.
              </p>
              <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                <a href="/privacy-policy" style={{color: '#666', fontSize: '14px', textDecoration: 'none', borderBottom: '1px solid #666'}}>Privacy Policy</a>
                <a href="/cookie-policy" style={{color: '#666', fontSize: '14px', textDecoration: 'none', borderBottom: '1px solid #666'}}>Cookie Policy</a>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <Button variant="danger" onClick={handleAcceptAll} style={{fontSize: '16px', padding: '12px', borderRadius: '25px'}}>
                  Accept All
                </Button>
                <Button variant="outline-secondary" onClick={handleRejectAll} style={{fontSize: '16px', padding: '12px', borderRadius: '25px'}}>
                  Reject All
                </Button>
                <Button variant="outline-danger" onClick={() => setShowCustomize(true)} style={{fontSize: '16px', padding: '12px', borderRadius: '25px'}}>
                  Customize Preferences
                </Button>
              </div>
            </>
          ) : (
            <>
              <h3 style={{color: '#c41e3a', marginBottom: '20px', fontSize: '22px', fontWeight: 'bold'}}>Cookie Settings</h3>
              <p style={{color: '#333', fontSize: '16px', marginBottom: '25px', lineHeight: '1.6'}}>Manage your cookie preferences</p>
              <div style={{flexGrow: 1, overflowY: 'auto', marginBottom: '20px'}}>
                <Accordion>
                  {cookieCategories.map(section => (
                    <CookieSection key={section.key} section={section} />
                  ))}
                </Accordion>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', gap: '10px'}}>
                <Button variant="outline-secondary" onClick={handleRejectAll} style={{fontSize: '14px', padding: '10px', borderRadius: '25px', flex: 1}}>
                  Reject All
                </Button>
                <Button variant="danger" onClick={handleSavePreferences} style={{fontSize: '14px', padding: '10px', borderRadius: '25px', flex: 1}}>
                  <Check size={16} className="me-2" />
                  Save Preferences
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CookieConsent;