import React, { useState, useEffect } from "react";
import "./App.css";
import AppRouter from "./AppRouter";

function App() {
  /*  const [messages, setMessages] = useState([]);
  useEffect(() => {
    const ws = new WebSocket("wss://machine.prientecloud.com/connect/ws"); // WebSocket bağlantı URL'nizi buraya girin

    ws.onopen = () => {
      console.log("WebSocket Connected");
      ws.send("livelog");
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message !== "dead") {
        const { data } = JSON.parse(event.data);
        if (data) {
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages, data];
            if (newMessages.length > 10) {
              newMessages.shift();
            }
            return newMessages;
          });
        }
      }
    };

    ws.onclose = () => {
      console.log("WebSocket Disconnected");
    };
    console.log(messages);
    // WebSocket bağlantısını temizle
  }, []);
 */
  return (
    <div className="App">
      
      <AppRouter />
    </div>
  );
}

export default App;
