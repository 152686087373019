import React, { useState, useEffect } from 'react';
import { Audio } from 'react-loader-spinner';
import { Helmet } from "react-helmet";

const Gift = ({ currentLang }) => {
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    company: '',
    phone: '',
    position: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({
    full_name: '',
    phone: '',
    email: '',
    company: '',
    position: ''
  });
  const [isLoading, setIsLoading] = useState(true);

  const content = {
    en: {
      title: 'Get Your Free Trial',
      subtitle: 'Experience Guardpot\'s Advanced Security Features',
      name: 'Full Name',
      email: 'Email', 
      company: 'Company Name',
      phone: 'Phone Number',
      position: 'Job Title',
      button: {
        default: 'Send',
        submitting: 'Processing...'
      },
      success: {
        title: 'Your Application is Received!',
        text: 'We have received your information. We will set up your 3-month package and get back to you as soon as possible.'
      },
      errors: {
        alreadyReceived: 'We could not receive your information',
        serverError: 'Server error occurred. Please try again.',
        invalidName: 'Please enter a valid name (min 3 characters)',
        invalidPhone: 'Please enter a valid phone number (+90XXXXXXXXXX)',
        invalidEmail: 'Please enter a valid email address',
        invalidCompany: 'Please enter company name',
        invalidPosition: 'Please enter your position'
      }
    }
  };

  const t = content[currentLang || 'en'];

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const validateForm = () => {
    let isValid = true;
    const errors = {
      full_name: '',
      phone: '',
      email: '',
      company: '',
      position: ''
    };

    if (!formData.full_name.trim() || formData.full_name.length < 3) {
      errors.full_name = t.errors.invalidName;
      isValid = false;
    }

    if (!formData.phone.trim() || !/^\+?[0-9]{10,13}$/.test(formData.phone.replace(/[\s()-]/g, ''))) {
      errors.phone = t.errors.invalidPhone;
      isValid = false;
    }

    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = t.errors.invalidEmail;
      isValid = false;
    }

    if (!formData.company.trim()) {
      errors.company = t.errors.invalidCompany;
      isValid = false;
    }

    if (!formData.position.trim()) {
      errors.position = t.errors.invalidPosition;
      isValid = false;
    }

    setFieldErrors(errors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setError(null);
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    setFieldErrors(prev => ({
      ...prev,
      [name]: ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    setError(null);

    try {
      // Send to Allarmia API
      const response = await fetch('https://allarmia.com/api/listeners/6_355932', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            notify: `
              Name:${formData.full_name}
              Email:${formData.email}
              Company:${formData.company}
              Phone:${formData.phone}
              Position:${formData.position}
              Application:Guardpot
              event:Trial Request
            `
          }
        })
      });

      if (response.ok) {
        setIsSuccess(true);
      } else {
        setError(t.errors.serverError);
      }
    } catch (err) {
      setError(t.errors.serverError);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Guardpot | Free Trial</title>
        <meta name="description" content="Get your free trial access to Guardpot's advanced honeypot cybersecurity solutions." />
      </Helmet>

      <div className="gift-page">
        {isLoading ? (
          <div className="loader-container">
            <Audio height="80" width="80" radius="9" color="#c41e3a" ariaLabel="loading" />
          </div>
        ) : (
          <div className="gift-content">
            <div className="header-section">
              <h1>{t.title}</h1>
              <p>{t.subtitle}</p>
            </div>

            <div className="form-container">
              {isSuccess ? (
                <div className="success-message">
                  <h2>{t.success.title}</h2>
                  <div className="message-display">
                    <p>{t.success.text}</p>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleInputChange}
                      placeholder={t.name}
                      className={fieldErrors.full_name ? 'error' : ''}
                    />
                    {fieldErrors.full_name && <span className="error-message">{fieldErrors.full_name}</span>}
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder={t.email}
                      className={fieldErrors.email ? 'error' : ''}
                    />
                    {fieldErrors.email && <span className="error-message">{fieldErrors.email}</span>}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      placeholder={t.company}
                      className={fieldErrors.company ? 'error' : ''}
                    />
                    {fieldErrors.company && <span className="error-message">{fieldErrors.company}</span>}
                  </div>

                  <div className="form-group">
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder={t.phone}
                      className={fieldErrors.phone ? 'error' : ''}
                    />
                    {fieldErrors.phone && <span className="error-message">{fieldErrors.phone}</span>}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="position"
                      value={formData.position}
                      onChange={handleInputChange}
                      placeholder={t.position}
                      className={fieldErrors.position ? 'error' : ''}
                    />
                    {fieldErrors.position && <span className="error-message">{fieldErrors.position}</span>}
                  </div>

                  <button 
                    type="submit" 
                    disabled={isSubmitting}
                    className="submit-button"
                  >
                    {isSubmitting ? t.button.submitting : t.button.default}
                  </button>
                </form>
              )}

              {error && <div className="error-alert">{error}</div>}
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        .gift-page {
          min-height: 100vh;
          background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
          padding: 2rem;
        }

        .loader-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }

        .gift-content {
          max-width: 800px;
          margin: 0 auto;
          padding-top: 80px;
        }

        .header-section {
          text-align: center;
          margin-bottom: 3rem;
        }

        .header-section h1 {
          color: #c41e3a;
          font-size: 2.5rem;
          margin-bottom: 1rem;
        }

        .header-section p {
          color: #666;
          font-size: 1.2rem;
        }

        .form-container {
          background: white;
          padding: 2rem;
          border-radius: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .form-group {
          margin-bottom: 1.5rem;
        }

        input {
          width: 100%;
          padding: 0.8rem;
          border: 2px solid #eee;
          border-radius: 5px;
          font-size: 1rem;
          transition: border-color 0.3s;
        }

        input:focus {
          border-color: #c41e3a;
          outline: none;
        }

        input.error {
          border-color: #ff4444;
        }

        .error-message {
          color: #ff4444;
          font-size: 0.8rem;
          margin-top: 0.5rem;
        }

        .submit-button {
          width: 100%;
          padding: 1rem;
          background: #c41e3a;
          color: white;
          border: none;
          border-radius: 5px;
          font-size: 1.1rem;
          cursor: pointer;
          transition: background 0.3s;
        }

        .submit-button:hover {
          background: #a01830;
        }

        .submit-button:disabled {
          background: #cccccc;
          cursor: not-allowed;
        }

        .success-message {
          text-align: center;
          padding: 2rem;
        }

        .success-message h2 {
          color: #c41e3a;
          margin-bottom: 2rem;
        }

        .code-display {
          background: #f8f9fa;
          padding: 2rem;
          border-radius: 5px;
        }

        .code {
          font-size: 1.5rem;
          font-weight: bold;
          color: #c41e3a;
          margin-top: 1rem;
        }

        .error-alert {
          background: #ffebee;
          color: #c41e3a;
          padding: 1rem;
          border-radius: 5px;
          margin-top: 1rem;
          text-align: center;
        }
      `}</style>
    </>
  );
};

export default Gift;