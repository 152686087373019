import React, { useEffect, useRef, useState } from "react";
import Globe from "react-globe.gl";
import Head from "./Head";
import Footer from "./Footer";
import { Audio } from "react-loader-spinner";
import LastAttacks from "./livelogs";
import LiveStats from "./livestats";
import { isMobile } from "react-device-detect";
import map from "./assets/map3.png";
// Record gösterimi için küçük bir bileşen
import { useInterval } from './util';

const GlobeWithWebSocketData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [autoRotate, setAutoRotate] = useState(true);
  const globeEl = useRef();
  const [attacks, setAttackers] = useState([]);

  const [search, setSearch] = useState([]);
  const lockRef = useRef(false);


  const acquireLock = () => {
    if (lockRef.current) {
      return false; // Lock zaten alınmış
    }
    lockRef.current = true;
    return true;
  };


  const releaseLock = () => {
    lockRef.current = false;
  };

  useEffect(() => {
    if (!isLoading) {
      setAutoRotate(true);
      globeEl.current.controls().autoRotate = true;
      globeEl.current.controls().autoRotateSpeed = 1;
      globeEl.current.controls().enableZoom = true;
      globeEl.current.controls().maxDistance = 1500;
      globeEl.current.controls().minDistance = 400;

      /*  globeEl.current.pointOfView({ lat: 37, lng: 35, altitude: 3.5 }, 7000); */
    }
  }, [isLoading]);
  const [windowSize, setWindowSize] = useState({
    width: 1920,
    height: 1080,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const buttonList = [
    {
      title: 'SSH',
      values: ['ssh-login', 'ssh-command'],
      color: 'orange'
    },
    {
      title: 'FTP',
      values: ['ftp-command', 'ftp-login'],
      color: 'yellow'
    },
    {
      title: 'TCP PORTSCAN',
      values: ['tcp-portscan'],
      color: 'lightblue'
    },
    {
      title: 'UDP PORTSCAN',
      values: ['udp-portscan'],
      color: 'green'
    },
    {
      title: 'DNS',
      values: ['dns-query'],
      color: 'purple'
    },
    {
      title: 'LDAP',
      values: ['ldap-login'],
      color: '#00b8ff'
    },
    {
      title: 'WEB LOGIN',
      values: ['fortinet-login', 'vsphere-login', 'filemanager-login', 'adminer-login', 'phpmyadmin-login', 'nextcloud-login']
      , color: 'pink'
    },
    {
      title: 'DATABASE',
      values: ['postgresql-login', 'mssql-login', 'mysql-login'],
      color: 'purple'
    }
  ]


  const filter = (values) => {
    if (search?.length === 0) {
      return values
    } else {
      if (search?.length === 16) {
        return values?.filter(item => !search.includes(item?.record?.type))
      } else {
        return values?.filter(item => search.includes(item?.record?.type))
      }
    }
  };

  const filterArcs = (values) => {
    if (search?.length === 0) {
      return values
    } else {
      if (search?.length === 16) {
        return values?.filter(item => !search.includes(item?.type))
      } else {
        return values?.filter(item => search.includes(item?.type))
      }
    }
  };

  const filterRings = (values) => {
    if (search?.length === 0) {
      return values
    } else {
      if (search?.length === 16) {
        return values?.filter(item => !search.includes(item?.type))
      } else {
        return values?.filter(item => search.includes(item?.type))
      }
    }
  };

  const [arcsData, setArcsData] = useState([]);
  const [ringsData, setRingsData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentLog, setCurrentLog] = useState(null);
  const [agentState, setAgentState] = useState(null);
  const ARC_REL_LEN = 0.7; // relative to whole arc
  const FLIGHT_TIME = 2500;
  const NUM_RINGS = 2;
  const RINGS_MAX_R = 3; // deg
  const RING_PROPAGATION_SPEED = 5; // deg/sec
  const MAP_CENTER = { lat: 39.0, lng: 35.0, altitude: 2.0 };
  const arcColors = {
    "tcp-portscan": "lightblue",
    "ssh-login": "orange",
    "ssh-command": "yellow",
    "ftp-command": "yellow",
    "fortinet-login": "pink",
    "vsphere-login": "pink",
    "dns-query": "#00b8ff",
    "postgresql-login": "purple",
    "ldap-login": "purple",
    "mssql-login": "purple",
    "mysql-login": "purple",
    "filemanager-login": "pink",
    "adminer-login": "pink",
    "phpmyadmin-login": "pink",
    "nextcloud-login": "pink",
  };


  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    // const ws = new WebSocket(`wss://${currentUrl.hostname}/connect/ws`);
    const ws = new WebSocket(`wss://guardpot.com/connect/ws`);

    //const ws = new WebSocket("wss://machine.prientecloud.com/connect/ws");

    if (ws.CLOSED) {
      ws.onopen = () => {
        ws.send("livelog");
        ws.send("liveness");
        setInterval(() => {
          if (ws.OPEN) {
            //ws.send("liveloghb");
            ws.send("liveness");
          } else {
            const ws = new WebSocket(`wss://${currentUrl.hostname}/connect/ws`);
          }
        }, 10000);
      };
    }
    /* ws.onopen = () => {
      console.log("WebSocket Connected");
      ws.send("livelog");
      setInterval(() => {
        ws.send("liveloghb");
      }, 10000);
    }; */

    ws.onmessage = (event) => {
      let data;
      try {
        data = JSON.parse(event.data);
      } catch (error) {
        data = "live";
      }
      if (data?.data?.logs) {
        if (acquireLock()) {

          setAttackers((arcsData) => [...arcsData,
          ...Object.values(data?.data?.logs)
            ?.filter((item) => item !== "undefined")
            .map((item) => JSON.parse(item))
            .filter((item) => item != undefined)
            .map((item) => (item?.data ? item?.data : "null"))
            .filter((item) => item !== "null")
          ]);

          data?.data?.logs?.map(item => JSON.parse(item)).map((log, index) => {


            const logInner = log;
            const from = logInner?.data?.attackerLocation;
            const to = logInner?.data?.agentLocation;
            const record = logInner?.data?.stats;
            const startLat = parseFloat(from.long);
            const startLng = parseFloat(from.lat);
            const endLat = parseFloat(to.lat);
            const endLng = parseFloat(to.long);

            if (index === 0) {
              setCurrentRecord(record); // record verisini set ediyoruz
              setCurrentLog(record);
            }
            const arc = {
              startLat,
              startLng,
              endLat,
              endLng,
              type: logInner.data.record.type,
            };
            setArcsData((arcsData) => [...arcsData, arc]);

            const srcRing = {
              lat: startLat,
              lng: startLng,
              type: logInner.data.record.type,
            };
            setRingsData((ringsData) => [...ringsData, srcRing]);


            setArcsData((arcsData) => {
              const newArcsData = [...arcsData, arc];

              setTimeout(() => {
                setArcsData((arcsData) =>
                  arcsData.filter((a) => a !== arc)
                );
              }, 2500); // Belirtilen süre sonunda oku kaldır

              return newArcsData;
            });

            // Ring ekleme ve timeout ile kaldırma
            setRingsData((ringsData) => {
              const newRingsData = [...ringsData, srcRing];

              setTimeout(() => {
                setRingsData((ringsData) =>
                  ringsData.filter((r) => r !== srcRing)
                );
              }, 2500); // Belirtilen süre sonunda ringi kaldır

              return newRingsData;
            });




            // Arc kaldırma
            // const removeArcTimeout = setTimeout(() => {
            //   setArcsData((arcsData) => arcsData.filter((d) => d !== arc));
            // }, FLIGHT_TIME * 1.8);

            // Başlangıç halkası ekleme ve kaldırma

            const removeSrcRingTimeout = setTimeout(() => {
              setRingsData((ringsData) =>
                ringsData.filter((r) => r !== srcRing)
              );
            }, FLIGHT_TIME * ARC_REL_LEN);

            // Hedef halkası ekleme ve kaldırma
            const addTargetRingTimeout = setTimeout(() => {
              const targetRing = {
                lat: endLat,
                lng: endLng,
                type: logInner.data.record.type,
              };
              setRingsData((ringsData) => [...ringsData, targetRing]);
              setTimeout(() => {
                setRingsData((ringsData) =>
                  ringsData.filter((r) => r !== targetRing)
                );
              }, FLIGHT_TIME * ARC_REL_LEN);
            }, FLIGHT_TIME);

          });
        }
        releaseLock();
        setIsLoading(false);
      } else if (data?.data?.response) {
        const data = JSON.parse(event.data);
        setAgentState(data);
      } else {
        //console.log('live')
      }
    };

  }, []);
  
  const getCountOfEnsume = (attacksLength) => {
    if (attacksLength < 100) return 40;
    if (attacksLength >= 100 && attacksLength < 200) return 50;
    return 100;
  };

  useInterval(() => {
    if (acquireLock()) {
      setAttackers(prevQueue => {
        const countOfEnsume = getCountOfEnsume(prevQueue.length);
        if (prevQueue.length > countOfEnsume) {
          return prevQueue.slice(prevQueue.length - countOfEnsume);
        }
        return prevQueue;
      });
      releaseLock();
    }
  }, 1500);



  const nextSectionRef = useRef();
  const previousSectionRef = useRef();


  const [nextPage, setNextPage] = useState(true);

  function scrollTo() {
    if (nextPage) {
      window.scrollTo({
        top: nextSectionRef.current?.offsetTop + 200,
        left: 0,
        behavior: "smooth"
      })
      setNextPage(false)
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
      setNextPage(true)

    }

  }


  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Audio
            height="80"
            width="80"
            radius="9"
            color="red"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
          <h2
            style={{
              color: '#c41e3a'
            }}
          >
            Waiting Logs
          </h2>
        </div>
      ) : (
        <>
          <Head record={currentRecord} isMobil={isMobile} />
          {!isMobile &&
            <div className="px-3 py-1 w-100" >
              <div
                className="d-flex align-items-center justify-content-between px-5 py-3"
                style={{ position: "fixed", top: "8.5%", zIndex: "1001", width: windowSize.width * 0.975, background: '#131521', color: 'white', borderRadius: '12px' }}
              >
                <div>
                  <div
                    className={`customButton px-3 py-1`}
                    style={{ background: 'white', color: 'black', border: `2px solid white` }}
                    onClick={() => setSearch([])}
                  >
                    {"CLEAR"}
                  </div>
                </div>
                {buttonList.map(item => {
                  return (
                    <div>
                      <div
                        className={`customButton px-3 py-1`}
                        style={search.some(y => item.values.some(elem => elem === y)) ?
                          { background: 'black', color: 'white', border: `4px solid ${item.color}` } :
                          { background: 'black', color: 'white', border: `4px solid black` }
                        }

                        onClick={() => {
                          if (search.some(y => item.values.some(elem => elem === y))) {
                            setSearch(search.filter(elem => !item.values.some(x => x === elem)))
                          } else {
                            setSearch([...search, ...item.values])
                          }
                        }}
                      >
                        {item.title}
                      </div>
                    </div>
                  )
                })}

              </div>
            </div>
          }
          <div className="App" style={{ overflowX: 'hidden' }}>
            <section ref={previousSectionRef}>

              <Globe
                ref={globeEl}
                globeImageUrl={map}
                backgroundImageUrl={
                  "//unpkg.com/three-globe/example/img/night-sky.png"
                }
                bumpImageUrl={
                  "//unpkg.com/three-globe/example/img/earth-topology.png"
                }
                backgroundColor="rgba(255, 255, 255, 0.0)"
                arcsData={filterArcs(arcsData)}
                showAtmosphere={false}
                arcColor={(d) => arcColors[d.type] || "darkOrange"}
                arcStroke={0.7} // Okların kalınlığını arttırmak için stroke değeri eklendi
                arcDashLength={0.5}
                arcDashGap={0.3}
                arcDashAnimateTime={2500}
                atmosphereAltitude={0.1}
                ringsData={filterRings(ringsData)}
                ringColor={(d) => arcColors[d.type] || "darkOrange"}
                ringMaxRadius={RINGS_MAX_R}
                ringPropagationSpeed={RING_PROPAGATION_SPEED}
                ringRepeatPeriod={(FLIGHT_TIME * ARC_REL_LEN) / NUM_RINGS}

              />
            </section>


            <section ref={nextSectionRef} style={{ paddingBottom: isMobile ? '5rem' : '0rem', background: 'black' }}>
              <LastAttacks isMobile={isMobile} totalData={filter(attacks).length} data={filter(attacks.slice(-getCountOfEnsume(attacks.length)))} windowSize={windowSize} />
            </section>
            {!isMobile && <LiveStats currentLog={currentLog} agentState={agentState} />}
          </div>

          {isMobile && <>
            <div className="w-100" style={{ position: 'fixed', left: '0px', bottom: '-1px', zIndex: '9999' }}>
              <button
                onClick={scrollTo}
                className="btn w-100" style={{ background: '#b02f34', fontSize: '16px', letterSpacing: '5px', color: 'white', fontWeight: '700', padding: '10px', outline: 'none' }}>
                {nextPage ? 'SHOW LAST ATTACKS' : 'SHOW WORLD'}
              </button>
            </div>
          </>}

        </>
      )
      }
    </>
  );
};

export default GlobeWithWebSocketData;
